module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true,"pathToStylesProvider":"C:\\code\\gatsby\\cosmetiese.deKopie/src/components/stylesProviderProps.ts"},"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://cosmetiese.de","canonical":"https://cosmetiese.de","site_name":"Institut für Personalisert Kosmetik, Inhaberin Dr. med. Anna Maria Müller","title":"COSMETIESE","titleTemplate":"COSMETIESE| %s","description":"COSMETIESE: Institut für Personalisert Kosmetik, Inhaberin Dr. med. Anna Maria Müller.","language":"de","openGraph":{"type":"website","site_name":"Institut für Personalisert Kosmetik, Inhaberin Dr. med. Anna Maria Müller","locale":"de_DE","url":"https://cosmetiese.de","title":"COSMETIESE","description":"COSMETIESE: Institut für Personalisert Kosmetik, Inhaberin Dr. med. Anna Maria Müller.","images":[{"url":"https://cosmetiese.de/default.jpg","width":800,"height":600,"alt":"Og Image Alt"}]},"handle":"@cosmetiese","site":"@cosmetiese","cardType":"summary_large_image"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Institut für Personalisierte Kosmetik COSMETIESE, Inhaberin Dr. med. Anna Maria Müller","short_name":"COSMETIESE","description":"CCOSMETIESE: Institut für Personalsierte Kosmetik, spezialisiert auf Produkte und Behandlungen der personalisierten High-End-Hautpflege von Biologique Recherche, Inhaberin Dr. med. Anna Maria Müller.","start_url":"/","lang":"de","display":"standalone","icon":"static/favicons/favicon.png","background_color":"#666","theme_color":"#fff","icons":[{"src":"static/favicons/favicon_512px.png","sizes":"512x512","type":"image/png","purpose":"any"},{"src":"static/favicons/favicon_256px.png","sizes":"256x256","type":"image/png","purpose":"any"},{"src":"static/favicons/favicon_192px.png","sizes":"192x192","type":"image/png","purpose":"any"},{"src":"static/favicons/favicon_127px.png","sizes":"127x127","type":"image/png","purpose":"any"},{"src":"static/favicons/favicon_96px.png","sizes":"96x96","type":"image/png","purpose":"any"},{"src":"static/favicons/favicon_48px.png","sizes":"48x48","type":"image/png","purpose":"any"},{"src":"static/favicons/favicon_192_mascable.png","sizes":"192x192","type":"image/png","purpose":"maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"966878d77848401bc872797c139bc422"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
